var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "tiers-container" },
    _vm._l(_vm.subscriptions, function (tier) {
      return _c("tier-panel", {
        key: tier.stripeId,
        attrs: { tier: tier },
        on: { select: _vm.handleSelect },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }