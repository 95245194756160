var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", _vm._b({}, "section", _vm.panelAttrs, false), [
    _c("p", { staticClass: "dn-tier__name" }, [_vm._v(_vm._s(_vm.tier.name))]),
    _c("div", { staticClass: "dn-tier__price" }, [
      _c("h1", [_vm._v("$" + _vm._s(_vm._f("money")(_vm.tier.price)))]),
      _c("p", [_vm._v("/ month")]),
    ]),
    _c("p", { staticClass: "dn-tier__description" }, [
      _vm._v(_vm._s(_vm.tier.description)),
    ]),
    _c(
      "ul",
      { staticClass: "dn-tier__features" },
      _vm._l(_vm.tier.content, function (content) {
        return _c("li", {
          key: content,
          staticClass: "dn-tier__feature",
          domProps: { innerHTML: _vm._s(content) },
        })
      }),
      0
    ),
    _c(
      "button",
      {
        directives: [{ name: "ripple", rawName: "v-ripple" }],
        staticClass: "dn-btn",
        on: {
          click: function ($event) {
            return _vm.$emit("select", _vm.tier)
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.tier.ctaText) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }